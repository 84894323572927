<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" :offset="6">
        <el-form class="mt-4" :label-position="labelPosition" label-width="110px" :model="dataForm" :rules="dataRule"
                 ref="dataForm" status-icon>
          <el-form-item prop="nickname" label="新昵称：" style="margin-top: 40px;margin-bottom: 40px;">
            <el-col :span="24">
              <el-input v-model="dataForm.nickname" placeholder="输入新昵称" clearable></el-input>
            </el-col>
          </el-form-item>
          <el-form-item style="margin-top: 40px;margin-bottom: 40px;">
            <div style="text-align:center">
              <el-button type="primary" @click="submitForm" style="margin-right: 20px" :loading="loading">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  export default {
    computed: {
      name: {
        get () { return this.$store.state.user.name },
        set (val) { this.$store.commit('user/updateName', val) }
      }
    },
    data() {
      return {
        dataForm: {
          nickname: ''
        },
        dataRule: {
          nickname: [{required: true, message: '昵称不能为空', trigger: 'blur'}]
        },
        labelPosition: 'right',
        loading: false
      }
    },
    created() {
    },
    methods: {
      submitForm () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.loading = true
              this.$http({
                url: this.$http.adornUrl(this.$api.SYS.USER.CHANGE_NICKNAME),
                method: 'POST',
                params: this.$http.adornParams({
                  'nickname': this.dataForm.nickname
                }),
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.loading = false
                  this.$message.success('昵称修改成功！')
                  this.name = this.dataForm.nickname
                } else {
                  this.loading = false
                  this.$message.error(data.msg)
                }
              })
          }
        })
      },
      resetForm () {
        this.$refs['dataForm'].resetFields()
      }
    }
  }
</script>

<style scope>
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

</style>
