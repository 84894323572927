<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :span="7">
        <el-card shadow="never">
          <div class="full-navbar">
            <div class="full-navbar__header">
              <img :src="userHead ? $cons.SERVER_PATH + userHead : defAvatarUrl" style="border-radius: 100%;width: 124px;height: 124px" >
              <div style="color: rgba(0,0,0,.85);font-weight: bold;font-size: 20px;line-height: 28px;margin-top: 20px">{{ name }}</div>
              <div style="margin-top: 10px;color: #909399;font-size: 14px;margin-bottom: 30px">平台企业用户</div>
            </div>
            <div class="full-navbar__body">
              <div class="subtitle">
                <label class="item-label" style="width: 75px">账号名称：</label>
                <div class="item-content" style="margin-left: 75px">{{ userName || '无' }}</div>
              </div>
              <div class="subtitle">
                <label class="item-label" style="width: 75px">所在地区：</label>
                <div class="item-content" style="margin-left: 75px">{{ orgName || '无' }}</div>
              </div>
              <div class="subtitle">
                <label class="item-label" style="width: 75px">联系电话：</label>
                <div class="item-content" style="margin-left: 75px">{{ mobile || '无' }}</div>
              </div>
              <div class="subtitle">
                <label class="item-label" style="width: 75px">邮箱地址：</label>
                <div class="item-content" style="margin-left: 75px">{{ email || '无' }}</div>
              </div>
              <div class="subtitle">
                <label class="item-label" style="width: 75px">注册时间：</label>
                <div class="item-content" style="margin-left: 75px">{{ registerTime || '无' }}</div>
              </div>
            </div>
          </div>
          <aside class="full-sidebar">
            <div class="full-sidebar__inner">
              <el-menu
                unique-opened
                default-active="changePassword"
                class="full-sidebar__menu">
                <el-menu-item v-for="menu in menuList" :key="menu.key" :index="menu.key" @click="menuClick(menu)">
                  <i :class="menu.icon" class="site-sidebar__menu-icon"></i>
                  <span>{{ menu.label }}</span>
                </el-menu-item>
              </el-menu>
            </div>
          </aside>
        </el-card>
      </el-col>
      <el-col :span="17">
        <el-card shadow="never">
          <div class="i-title">
            <h3>{{ activeTitle }}</h3>
          </div>
          <change-password v-if="activeTab === 'changePassword'"/>
          <change-mobile v-if="activeTab === 'changeMobile'"/>
          <change-nickname v-if="activeTab === 'changeNickname'"/>
          <change-avatar v-if="activeTab === 'changeAvatar'"/>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import ChangePassword from './components/change-password'
  import ChangeMobile from './components/change-mobile'
  import ChangeNickname from './components/change-nickname'
  import ChangeAvatar from './components/change-avatar'


  export default {
    name: 'Profile',
    components: {
      ChangePassword,
      ChangeMobile,
      ChangeNickname,
      ChangeAvatar
    },
    data() {
      return {
        user: {},
        menuList: [
          { icon: 'el-icon-lock', label: ' 修改密码', key: 'changePassword' },
          { icon: 'el-icon-mobile-phone', label: ' 修改绑定手机号', key: 'changeMobile' },
          { icon: 'el-icon-edit', label: ' 修改昵称', key: 'changeNickname' },
          { icon: 'el-icon-user', label: ' 修改头像', key: 'changeAvatar' }
        ],
        activeTab: 'changePassword',
        activeTitle: '修改密码',
        defAvatarUrl: require('../../../../assets/img/male.jpg'),
        userAvatarUrl: "",
      }
    },
    computed: {
      name: {
        get () { return this.$store.state.user.name }
      },
      userName: {
        get () { return this.$store.state.user.userName }
      },
      mobile: {
        get () { return this.$store.state.user.mobile }
      },
      orgCode: {
        get () { return this.$store.state.user.orgCode }
      },
      orgName: {
        get () { return this.$store.state.user.orgName }
      },
      accountType: {
        get () { return this.$store.state.user.accountType }
      },
      email: {
        get () { return this.$store.state.user.email }
      },
      registerTime: {
        get () { return this.$store.state.user.registerTime }
      },
      userHead: {
        get () { return this.$store.state.user.userHead }
      }
    },
    created() {
    },
    methods: {
      menuClick (menu) {
        this.activeTab = menu.key
        this.activeTitle = menu.label
      }
    }
  }
</script>
<style lang="scss">
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #303133;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .full-navbar {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 15px;
    border-bottom: 1px dashed #e8e8e8;
    &__header {
      margin: 10px;
      text-align: center;
      img {
        border-radius: 100%;width: 80%
      }
    }
    &__body {
      color: #606266;
      padding-left: 15px;
      padding-top: 10px;
      .subtitle {
        font-size: 14px;
        margin: 12px 0;
        line-height: 20px;
        height: 26px;
        .item-label {
          vertical-align: middle;
          float: left;
          color: #606266;
          line-height: 20px;
          box-sizing: border-box;
        }
        .item-content {
          line-height: 20px;
          position: relative;
        }
      }
    }
    &__divider {
      border: dashed #e8e8e8;
      border-width: 1px 0 0;
      margin: 24px 0;
      min-width: 100%;
      display: block;
      height: 1px;
    }
    &__label {
      padding-left: 15px;
      .button-new-tag {
        height: 28px;
        line-height: 26px;
        padding: 0 10px;
      }
    }
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  .full-sidebar {
    overflow: hidden;
    transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
    &__inner {
      height: 100%;
      padding-bottom: 15px;
      overflow-y: hidden;
      overflow-x: hidden;
      transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
    }
    &__menu.el-menu {
      border-right: 0;
      transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
      .is-active {
        border-left: 2px solid #409EFF;
      }
    }
    &__menu-icon {
      width: 24px;
      margin-right: 5px;
      text-align: center;
      font-size: 16px;
      color: inherit !important;
      transition: inline-block .3s, left .3s, width .3s, margin-left .3s, font-size .3s;
    }
  }

</style>
