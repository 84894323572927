<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12" :offset="6">
        <el-form class="mt-4" :label-position="labelPosition" label-width="110px" :model="dataForm" :rules="dataRule"
                 ref="dataForm" status-icon>
          <el-form-item prop="mobile" label="新手机号：" style="margin-top: 40px;margin-bottom: 40px;">
            <el-col :span="24">
              <el-input v-model="dataForm.mobile" placeholder="输入新的手机号" clearable></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="smsCode" label="短信验证码：" style="margin-top: 40px;margin-bottom: 40px;">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-input v-model="dataForm.smsCode" placeholder="短信验证码"></el-input>
              </el-col>
              <el-col :span="10">
                <div class="qrcode">
                  <template v-if="smsSuc">
                    <el-button type="danger">{{ smsTime }}</el-button>
                  </template>
                  <template v-else>
                    <el-button type="text" @click="getMsgCode">获取短信验证码</el-button>
                  </template>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
          <!--图片验证码-->
          <el-form-item prop="captcha" label="验证码：" style="margin-top: 40px;margin-bottom: 40px;">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-input v-model="dataForm.captcha" placeholder="验证码" clearable></el-input>
              </el-col>
              <el-col :span="6" class="qrcode">
                <img :src="captchaPath" @click="getCaptcha" alt="" style="height:30px;width:90px;margin-top:3px;">
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="margin-top: 40px;margin-bottom: 40px;">
            <div style="text-align:center">
              <el-button type="primary" @click="submitForm" style="margin-right: 20px" :loading="loading">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import {getUUID} from '@/utils'
  import {isMobile} from '../../../../../utils/validate'

  export default {
    data() {
      const checkMobile = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入手机号码'))
        } else if (!isMobile(value)) {
          return callback(new Error('手机号码格式不正确'))
        } else {
          callback()
        }
      }
      return {
        dataForm: {
          mobile: '',
          captcha: '',
          smsCode: '',
        },
        captchaPath: '',
        dataRule: {
          captcha: [
            {required: true, message: '验证码不能为空', trigger: 'blur'}
          ],
          mobile: [
            {required: true, validator: checkMobile, trigger: 'blur'}
          ],
          smsCode: [{required: true, message: '短信验证码不能为空', trigger: 'blur'}]
        },
        loading: false,
        smsSuc: false,
        smsTime: '',
        labelPosition: 'right'
      }
    },

    created() {
      this.getCaptcha()
    },

    methods: {
      // 获取验证码
      getCaptcha () {
        this.dataForm.uuid = getUUID()
        this.captchaPath = this.$http.adornUrl('/captcha.jpg?uuid=' + this.dataForm.uuid + '&t=' + new Date().getTime())
      },
      getMsgCode () {
        let _this = this
        this.$refs['dataForm'].validateField('mobile', (mobileMsg) => {
          if (mobileMsg === '') {
            _this.$http({
              url: _this.$http.adornUrl(_this.$api.SYS.VALID_CODE),
              method: 'post',
              data: _this.$http.adornData({
                'mobile': _this.dataForm.mobile,
                'smsTerminal': 'SMS_TERMINAL_VALID'
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message.success('短信发送成功，请注意查收')
                _this.smsSuc = true
                _this.smsTime = '120s后可重新获取'
                _this.countdown(120)
              } else {
                this.$message.error(data.msg)
                _this.getCaptcha()
              }
            })
          }
        })
      },
      countdown (time) {
        if (time < 1) {
          this.smsSuc = false
          return
        }
        this.smsSuc = true
        this.smsTime = time + 's后可重新获取'
        setTimeout(this.countdown, 1000, --time)
      },
      submitForm () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.loading = true
              this.$http({
                url: this.$http.adornUrl(this.$api.SYS.USER.CHANGE_MOBILE),
                method: 'POST',
                data: this.$http.adornData({
                  'mobile': this.dataForm.mobile, // Y 手机号
                  'smsCode': this.dataForm.smsCode, //  Y 验证码
                  'captcha': this.dataForm.captcha,
                }),
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.loading = false
                  this.$message('手机修改成功,请重新登录！')
                  this.$router.push({name: 'login'})
                } else {
                  this.getCaptcha()
                  this.$message.error(data.msg)
                  this.loading = false
                }
              })
            } else {
              this.$message.error('两次输入的密码不一致！')
            }
        })
      },
      resetForm () {
        this.$refs['dataForm'].resetFields()
      }
    }
  }
</script>

<style scope>
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

</style>
