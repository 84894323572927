<template>
  <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="6">
          <el-form class="mt-4" :label-position="labelPosition" label-width="110px" :model="dataForm" :rules="dataRule"
                   ref="dataForm" status-icon>
            <el-form-item prop="password" label="密码：" style="margin-top: 40px;margin-bottom: 40px;">
              <el-col :span="24">
                <el-input v-model="dataForm.password" placeholder="请输入密码" type="password"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item prop="newPassword" label="新密码：" style="margin-top: 40px;margin-bottom: 40px;">
              <el-col :span="24">
                <el-input v-model="dataForm.newPassword" placeholder="请输入新密码" type="password"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item prop="confirmNewPassword" label="确认密码：" style="margin-top: 40px;margin-bottom: 40px;">
              <el-col :span="24">
                <el-input v-model="dataForm.confirmNewPassword" placeholder="请确认新密码" type="password"></el-input>
              </el-col>
            </el-form-item>
            <el-form-item style="margin-top: 40px;margin-bottom: 40px;">
              <div style="text-align:center">
                <el-button type="primary" @click="submitForm" style="margin-right: 20px" :loading="loading">提交</el-button>
                <el-button @click="resetForm">重置</el-button>
              </div>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
  </div>
</template>

<script>
  import {encode64} from '@/utils'

  export default {
    data() {
      return {
        dataForm: {
          password: '',
          newPassword: '',
          confirmNewPassword: '',
          mobile: '',
          captcha: '',
          smsCode: '',
        },
        captchaPath: '',
        dataRule: {
          newPassword: [
            {required: true, message: '请输入新密码', trigger: 'blur'},
          ],
          confirmNewPassword: [
            {required: true, message: '请输入确认密码', trigger: 'blur'},
          ],
          password: [
            {required: true, message: '密码不能为空', trigger: 'blur'}
          ]
        },
        labelPosition: 'right',
        loading: false
      }
    },

    created() {
    },

    methods: {
      submitForm () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (
              this.dataForm.newPassword ===
              this.dataForm.confirmNewPassword
            ) {
              this.loading = true
              this.$http({
                url: this.$http.adornUrl(this.$api.SYS.USER.PASSWORD),
                method: 'POST',
                data: this.$http.adornData({
                  'password': encode64(this.$encryptECB(this.dataForm.password)), // Y 旧密码
                  'newPassword': encode64(this.$encryptECB(this.dataForm.newPassword)), // Y 新密码
                }),
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.loading = false
                  this.$message('密码修改成功,请重新登录！')
                  this.$router.push({name: 'login'})
                } else {
                  this.getCaptcha()
                  this.$message.error(data.msg)
                  this.loading = false
                }
              })
            } else {
              this.$message.error('两次输入的密码不一致！')
            }
          } else {
            return false
          }
        })
      },
      resetForm () {
        this.$refs['dataForm'].resetFields()
      }
    }
  }
</script>

<style>
  .i-title h3 {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }

</style>
